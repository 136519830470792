import { makeStyles } from "@material-ui/styles";

import imagenFondo from '../../images/fondo_gala.png'
export default makeStyles(theme => ({

    fondo:{
        backgroundImage: `url(${imagenFondo})`,
        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
        flex: 1,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
      }, 
      
    imagen2: {

        width: "100%", 
        margin: "auto" ,
  
    },    

    estiloboton:{
        width: '100%',
    },

    textotitulo:{      
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    textoDescripcion:{
        fontWeight: 'bolder !important',
    
    },

    imagenConfirmacionTitulo:{
        width: '50%',
        display: "block",
        margin: "auto",
    },

    profesionLabel: {
        fontSize: '1.3rem',
        color: '#c38b5d',
    },

    colorTextoGala:{
        color: '#000000'
    },

    mostrarTxtPc: {
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },

    mostratTxtMovil: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },

    textoNegrita: {
        fontWeight: 'bolder !important',
        display: "inline",
    },

    imgRecomendaciones: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },

    copyright: {
        marginTop: theme.spacing(4),
        whiteSpace: "nowrap",
        [theme.breakpoints.up("md")]: {
          position: "absolute",
          bottom: theme.spacing(2),
        },
    },

    formControl: {
        margin: theme.spacing(1),
    }

}));
