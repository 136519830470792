import React, { useState,useEffect } from "react";

import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'

function ExpaleRecaptcha()
{
   
    const[key,setkey]= React.useState("");

    const verifyCallback = token => {
        // Here you will get the final token!!!
        console.log(token, 'verifycallback')
        setkey(token);
      }

    useEffect(() => {
        loadReCaptcha('6Lc2hYonAAAAAMl2TtSPOatlmigxXbjrjKJ811vI')
      }, []);


    return( 

        <div>
            <ReCaptcha
            action='main'
            sitekey='6Lc2hYonAAAAAMl2TtSPOatlmigxXbjrjKJ811vI'
            verifyCallback={verifyCallback}
            />

            <h2>Google ReCaptcha with React </h2>

            <code>
            1. Add <strong>your site key</strong> in the ReCaptcha component. <br />
            2. Check <strong>console</strong> to see the token.
            </code>

            <header>
            <h1>Thanks for using `react-recaptcha-google`</h1>
            </header>
      </div>
       
    );


}

export default ExpaleRecaptcha;