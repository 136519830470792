import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";



// pages
import Error from "../pages/error";
import LayoutFormulario from '../pages/formulario/Layout';
import ExpaleRecaptcha from '../pages/formulario/ExpaleRecaptcha';
import Login from "../pages/login";
// components
import Layout from "./Layout";

// context
import { useUserState } from "../context/UserContext";
import { RouterRounded } from "@material-ui/icons";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <HashRouter>
      <Switch>
       {/*<Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />*/}
       <Route exact path="/formulario" render={() => <Redirect to="/" />} />
       <Route exact path="/app/" render={() => <Redirect to="/app/dashboard" />} />
       {/*<Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
       />*/}
       {/*isAuthenticated && <Route exact path="/login" render={() => <Redirect to="/app/dashboard" />} />*/}
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <Route path="/" component={LayoutFormulario} />
      

        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
