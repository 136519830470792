import React, { useState,useEffect } from "react";

import Container from '@material-ui/core/Container';
import Formulario from './Formulario';
import Confirmacion from './Confirmacion';
import {analitics} from  '../../services/firebase'
import useStyles from "./styles";

function Layout()
{

    var classes = useStyles();

    const[estado,setEstado]= React.useState(false);
    const[datos,setDatos]= React.useState({
        nombre:"",
        correo:"",
        telefono:"",
        colegiado:"",
        profesion:"",
        acompanante:false,
        recoger: "" ,
        nombreSede: ""
    });

    const cambiarEstato=()=>{
        setEstado(true);
    }

    const retornarADatos=()=>{
        setEstado(false);
    }

    useEffect(() => {
       // analitics.logEvent('Nueva visita',{name:"pagina principal"});
      }, []);


    return( 

        <div className={classes.fondo}>
            <Container maxWidth="sm" >
                {
                    estado===false ?
                        <Formulario cambiar={cambiarEstato} datos={datos} setDatos={setDatos} />
                    :
                        <Confirmacion cambiar={retornarADatos} datos={datos} setDatos={setDatos} />
                }
            </Container>
        </div>
       
    );


}

export default Layout;