import React, {useEffect} from "react";
import {Grid, CircularProgress} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import useStyles from "./styles";
import titulo from '../../images/titulo.png';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import tituloConfirmacion from '../../images/titulo-confirmacino.png';
import Widget from "../../components/Widget";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
//reCAPTCHA
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
// DB
import { db, funciones_fb } from "../../services/firebase";
//Imágenes
import vino from '../../images/eventos-Latam(2).png'
import gala from '../../images/imgGala3.png';
import noComida from '../../images/noComida2.png';
import noEbrio from '../../images/noEbrio2.png';
import noMascotas from '../../images/noMascotas2.png';
import noArmas from '../../images/noArmas2.png';
import noNinios from '../../images/noNinios2.png';
import vestimenta from '../../images/vestimenta2.png';
import carnet from '../../images/carne2.png';
import parqueo from '../../images/parqueo2.png';
import asientos from '../../images/asientos2.png';
import logoADG from '../../images/imgLogo2.png';
import invitado from '../../images/imgInvitado2.png';
import bienvenida from '../../images/imgBienvenida3.png';



function Confirmacion(props)
{

    var classes = useStyles();
    const {cambiar, datos, setDatos} = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [token, setToken] = React.useState("");
    const [finalizado, setFinalizado] = React.useState(false);
    const [msgError, setMsgError] = React.useState("");

    const verifyCallback = token => {
        //console.log(token);
        setToken(token);
    }

    useEffect(() => {
        loadReCaptcha('6Lc2hYonAAAAAMl2TtSPOatlmigxXbjrjKJ811vI')
    }, []);

    const regresarADatos=()=>{
        props.cambiar();
    }

    const GuardarDatos = () => {
        setIsLoading(true);
        setError(false);
        const currentTimeStamp = Math.floor(Date.now() / 1000);

        funciones_fb.httpsCallable("registrogala")({
            nombre: datos.nombre,
            correo: datos.correo,
            telefono: datos.telefono,
            colegiado: datos.colegiado,
            profesion: datos.profesion,
            fecha: currentTimeStamp,
            token: token,
            acompanante: datos.acompanante,
            recoger: datos.recoger,
            nombreSede: datos.nombreSede
        }).then(function(result) {
            var codigo = result.data.codigo;
            var mensaje = result.data.mensaje;

            //console.log("result", result);
            //console.log("result data", result.data);
            //console.log("codigo", codigo);

            if (codigo === 1){
                //console.log("Se guardó correctamente", codigo);
                //console.log("errorCode", mensaje);
                setIsLoading(false);
                setError(false);
                setFinalizado(true);
            } else {
                //console.log("Se guardó correctamente", codigo);
                //console.log("errorCode", mensaje);
                mensaje !== "" ? setMsgError(mensaje) : setMsgError("Ocurrió un error al procesar la solicitud, intenta recargar la página.");
                setIsLoading(false);
                setError(true);
            }
        }).catch(function (error) {
            setError(true);
            setIsLoading(false);
            setMsgError("Ocurrió un error al procesar la solicitud, intenta recargar la página.");
            console.log("error", error);
        });
    }

    return( 
        <>
        {!finalizado ? (
            <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <img className={classes.img} alt="complex" src={gala} style={{ marginTop: "5vh", width: "50%" }} />

                            <Grid container spacing={0} alignItems={"center"} justify={"space-between"} direction={'row'}>
                                <Grid item xs={12}>            
                                    <Typography variant={"h5"} align={"center"} className={`${classes.colorTextoGala}`}>
                                        Reglamento del Centro Cultural
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>            
                                    <Typography variant={"h5"} align={"center"} className={`${classes.textotitulo} ${classes.colorTextoGala}`} >
                                        Miguel Ángel Asturias para los asistentes:
                                    </Typography>   
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Widget
                                upperTitle
                                className={classes.card}
                                bodyClass={classes.fullHeightBody}
                                disableWidgetMenu
                            >
                                <IconButton onClick={regresarADatos}>
                                    <ArrowBackIcon />
                                </IconButton>
                            
                                <Grid container spacing={4}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                                    <Grid container item alignItems={"center"} >
                                        <Grid item xs={12}>            
                                            <Typography variant={"h6"} align={"center"} className={`${classes.colorTextoGala}`}>
                                                <strong>PROHIBICIONES</strong> DENTRO
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>            
                                            <Typography variant={"h6"} align={"center"} className={`${classes.textotitulo} ${classes.colorTextoGala}`} >
                                                DE LA GRAN SALA EFRAÍN RECINOS.
                                            </Typography>   
                                        </Grid>
                                    </Grid>

                                    <Grid container item alignItems={"flex-start"} justifyContent={"space-between"} direction={'row'} spacing={2} > {/*5*/}
                                        <Grid container item alignItems={"flex-start"} xs={6} spacing={2} >
                                            <Grid item xs={4}>
                                                <img alt="complex" src={noComida} className={classes.imgRecomendaciones} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant={"h6"} align={"left"} className={`${classes.colorTextoGala}`} >
                                                    <strong>Ingreso y consumo</strong> de goma de mascar, comidas o refrescos dentro de la sala durante la presentación.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <img alt="complex" src={noMascotas} className={classes.imgRecomendaciones} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant={"h6"} align={"left"} className={`${classes.colorTextoGala}`} >
                                                    <strong>Ingreso</strong> de mascotas.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <img alt="complex" src={noArmas} className={classes.imgRecomendaciones} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant={"h6"} align={"left"} className={`${classes.colorTextoGala}`} >
                                                    <strong>Ingreso</strong> de armas de fuego.
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={6} spacing={2} >
                                            <Grid item xs={4}>
                                                <img alt="complex" src={noEbrio} className={classes.imgRecomendaciones} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant={"h6"} align={"left"} className={`${classes.colorTextoGala}`} >
                                                <strong>Entrar a las salas</strong> en estado de ebriedad (el Centro Cultural "Miguel Ángel Asturias" se reserva el derecho de admisión).
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <img alt="complex" src={noNinios} className={classes.imgRecomendaciones} />
                                            </Grid>
                                            <Grid container item xs={8} alignItems={"center"} >
                                                <Typography variant={"h6"} align={"left"} className={`${classes.colorTextoGala}`} >
                                                    <strong>No</strong> llevar niños.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant={"h6"} align={"center"} className={`${classes.colorTextoGala}`}>
                                            <strong>RECOMENDACIONES:</strong>
                                        </Typography>
                                    </Grid>

                                    <Grid container item alignItems={"flex-start"} justifyContent={"space-between"} direction={'row'} spacing={2} > {/*5*/}
                                        <Grid container item alignItems={"flex-start"} xs={6} spacing={1} >
                                            <Grid item xs={4}>
                                                <img alt="complex" src={vestimenta} className={classes.imgRecomendaciones} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant={"h6"} align={"left"} className={`${classes.colorTextoGala}`} >
                                                    <strong>Código de Vestimenta: </strong> Traje de gala.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <img alt="complex" src={carnet} className={classes.imgRecomendaciones} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant={"h6"} align={"left"} className={`${classes.colorTextoGala}`} >
                                                    <strong>Llevar</strong> DPI o carné del CAG.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <img alt="complex" src={parqueo} className={classes.imgRecomendaciones} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant={"h6"} align={"left"} className={`${classes.colorTextoGala}`} >
                                                    <strong>Parqueo:</strong> Salir con tiempo y tomar en cuenta el horario de tráfico, el Colegio de Arquitectos no tendrá el control del parqueo.
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={6} spacing={2} justifyContent={"flex-end"} >
                                            <Grid item xs={4}>
                                                <img alt="complex" src={asientos} className={classes.imgRecomendaciones} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography variant={"h6"} align={"left"} className={`${classes.colorTextoGala}`} >
                                                <strong>No habrá asientos asignados</strong> para cada asistente, se estarán ubicando según vayan ingresando a la sala.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <img alt="complex" src={logoADG} style={{ maxWidth: "80%" }} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <img alt="complex" src={invitado} className={classes.imgRecomendaciones} />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <ReCaptcha
                                            action='main'
                                            sitekey='6Lc2hYonAAAAAMl2TtSPOatlmigxXbjrjKJ811vI'
                                            verifyCallback={verifyCallback}
                                    />

                                    { error===false &&
                                        <Grid item xs={12}>
                                            {isLoading ? (
                                                <div  className={classes.textotitulo}>
                                                    <CircularProgress/>
                                                </div>                                   
                                            ) : (
                                                <Button 
                                                    variant="contained" color="secondary" onClick={GuardarDatos} className={classes.estiloboton}>
                                                    Registrarse
                                                </Button>
                                            )}

                                        </Grid>
                                    }

                                    {  error &&
                                        <Grid item xs={12}>
                                            <Alert severity="error">
                                                {msgError}
                                            </Alert>
                                        </Grid>
                                    }

                                </Grid>
                            </Widget>
                        </Grid>
                    </Grid>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <img className={classes.img} alt="complex" src={gala} style={{ marginTop: "10vh", width: "80%" }} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography align={"center"} variant={"h2"} className={classes.textotitulo} color={"primary"} style={{ color: "#585756" }}>
                            Centro Cultural Miguel Ángel Asturias
                        </Typography>
                        <Typography align={"center"} variant={"h2"} className={classes.textotitulo} color={"primary"} style={{ color: "#585756"}}>
                            05 de septiembre
                        </Typography>
                        <Typography align={"center"} variant={"h2"} className={classes.textotitulo} color={"primary"} style={{ color: "#585756"}}>
                            19:00 a 21:00 horas
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                            <Widget
                                upperTitle
                                className={classes.card}
                                bodyClass={classes.fullHeightBody}
                                disableWidgetMenu
                            >
                                <Grid container spacing={4}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                                    <Grid item xs={12}>
                                        <Typography variant={"h5"} align={"center"} className={`${classes.textotitulo}`} color={"primary"} >
                                            <strong>¡Felicidades!</strong>
                                        </Typography>
                                        <Typography variant={"h5"} align={"center"} className={`${classes.textotitulo} ${classes.colorTextoGala}`} >
                                            Estimado (a) Arquitecto (a)
                                        </Typography>
                                        <Typography variant={"h5"} align={"center"} className={`${classes.textotitulo} ${classes.colorTextoGala}`} >
                                            Gracias por completar todos los pasos.
                                        </Typography>
                                        <Typography variant={"h5"} align={"center"} className={`${classes.textotitulo} ${classes.colorTextoGala}`} >
                                            Su registro ha sido confirmado.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Widget>
                    </Grid>

                    <Grid container item xs={12} alignItems={"center"} justifyContent={"center"} style={{ marginBottom: "10vh" }} >
                        <Grid item xs={7}>
                            <img className={classes.img} alt="complex" src={invitado} style={{ maxWidth: "80%" }} />
                        </Grid>

                        <Grid item xs={5}>
                            <img className={classes.img} alt="complex" src={logoADG} style={{ maxWidth: "40%" }} />
                        </Grid>
                    </Grid>

                </Grid>
            )
        }
        </>
    );
}

export default Confirmacion;