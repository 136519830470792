import {auth,db,fecha_hora} from "./setup";


export function watchUserChanges(callback) {

    const unsub= auth.onAuthStateChanged((user) => {

      
        if(user && !user.isAnonymous){

            callback({
                id:user.uid,
                email:user.email,
                displayName:user.displayName,               
            });      

        }else{
           
            callback(null); 
        }

    });

    return unsub;
}

export function watchDatosUSer(uid,callback) {
    const ultima_conexion=db.ref('usuarios/'+uid+'/configuraciones/ultima_conexion');
    
    ultima_conexion.onDisconnect().set(fecha_hora.database.ServerValue.TIMESTAMP); 
    callback([]);     
 

    return watchDatosUSer;
}

