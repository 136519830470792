import React,{ useState,useEffect } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {db} from '../../services/firebase';
// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Tables() {

  const [loading,setLoading] = React.useState(true);
  const[visible,setVisible]=React.useState(true);
  const[columnas,setColumnas] = React.useState(
    [
      {
        name: "correo",
        label: "Correo",
        options: {
                filter: true,
                sort: true,
            }
      },
      {
        name: "nombre",
        label: "Nombre",
        options: {
                filter: true,
                sort: true,
            }
      },
      {
        name: "telefono",
        label: "Teléfono",
        options: {
                filter: true,
                sort: true,
            }
      },
      {
        name: "fecha",
        label: "Fecha de ingreso",
        options: {
                filter: true,
                sort: true,
            }
      },
      {
        name: "colegiado",
        label: "Colegiado",
        options: {
                filter: true,
                sort: true,
            }
      },
      {
        name: "tipo_registro",
        label: "Tipo de Registro",
        options: {
                filter: true,
                sort: true,
            }
      },
      {
        name: "acompanante",
        label: "Acompañante",
        options: {
                filter: true,
                sort: true,
            }
      },
      {
        name: "recoger",
        label: "Recoger invitación en",
        options: {
                filter: true,
                sort: true, 
            }
      }

    ]
  );

  const [datosClientes,setDatosClientes] = React.useState([]);

  useEffect(() => {
    let listado=db.ref("evento_cag/").on("child_added",function(snapshot){      

      setDatosClientes( (prevState)=> {
      //console.log("datos",snapshot.val().acompanante);
        prevState.push({correo:snapshot.val().correo,
                        nombre:snapshot.val().nombre,
                        telefono:snapshot.val().telefono,
                        tipo_registro:snapshot.val().tipo_registro,
                        colegiado:snapshot.val().colegiado,
                        acompanante:snapshot.val().acompanante ? "Si" : "No" || "No",
                        recoger:snapshot.val().recoger ? (snapshot.val().recoger === "sede" ? snapshot.val().nombreSede : "Día del evento") : "",
                        fecha:new Date(snapshot.val().fecha * 1000).toLocaleString('es-MX')});
        return prevState;
       });
       setVisible(false);
       setVisible(true);
       setLoading(false);
    });

    return ()=>{
      db.ref("evento_cag").off("child_added",listado);
    }


  }, []);


  return (
    <>
      <PageTitle title="Registros" />
      {loading && <div>loading...<CircularProgress/></div>}
      <Grid container spacing={4}>
        <Grid item xs={12}>
{visible &&          <MUIDataTable
            
            options={{ 
              selectableRows: "none",
            }}
            data={datosClientes}
            columns={columnas}   
          />}
        </Grid>

      </Grid>
    </>
  );
}
