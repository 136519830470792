import React ,{ useState,useEffect } from "react";
//Material ui
import {Grid,CircularProgress,} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import useStyles from "./styles";
import Widget from "../../components/Widget";
import TextField from '@material-ui/core/TextField';
import titulo from '../../images/titulo.png';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
//Project imports
import {db,funciones_fb,analitics} from '../../services/firebase';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
import config from "../../config";
//Imagenes
import vino from '../../images/eventos-Latam(2).png'
import invitado from '../../images/imgInvitado2.png';
import logo from '../../images/imgLogo2.png';
import imgGala3 from '../../images/imgGala3.png';
import bienvenida3 from '../../images/imgBienvenida3.png';

function Formulario(props)
{
    const {cambiar, datos, setDatos} = props;

    var classes = useStyles();

    const[nombre,setNombre] = React.useState("");
    const[correo,setCorreo] = React.useState("");
    const[pais,setPais] = React.useState("");
    const[fechaNac,setFechaNac] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error,setError]=React.useState(false);
    const[token,setToken]= React.useState("");
    //Gala CAG
    const [colegiado, setColegiado] = useState("");
    const [profesion, setProfesion] = useState("");
    const [consultado, setConsultado] = useState(false);
    const [errorBuscar, setErrorBuscar] = useState(false);
    const [msgError, setMsgError] = useState("Por favor, intente de nuevo.");

    useEffect(() => {
        loadReCaptcha('6Lc2hYonAAAAAMl2TtSPOatlmigxXbjrjKJ811vI')
    }, []);

    const handleChangeNombre = (event) => {
        setDatos({
            ...datos,
            nombre: event.target.value
        });
    };

    const handleChangeCorreo = (event) => {
        setDatos({
            ...datos,
            correo: event.target.value
        });
    };

    const handleChangeTelefono = (event) => {
        const numTelefono = event.target.value;
        const regex = /^[0-9]*$/;

        if (regex.test(numTelefono) || numTelefono === '') {
            setDatos((prevDatos) => ({
                ...prevDatos,
                telefono: numTelefono
            }))
        }
    };

    const handleChangeColegiado = (event) => {
        const numColegiado = event.target.value;
        const regex = /^[0-9]*$/;

        if (regex.test(numColegiado) || numColegiado === '') {
            setColegiado(numColegiado);
        }
    }

    const SeleccionarProfesion = (event) => {
        setProfesion(event.target.value);
    };

    const SeleccionarSede = (event) => {
        setDatos({
            ...datos,
            nombreSede: event.target.value
        });
    };

    const HandleRecoger = (event) => {
        setDatos({
            ...datos,
            recoger: event.target.value,
            nombreSede: ""
        });
    };

    const ConsultarDatos = async () => {
        try {
            setIsLoading(true);
            await fetch(`${config.server}/CB_04062021/_GetInfoColegiado/_Events`, {
                method: 'POST',
                body: JSON.stringify({
                    Colegiado: colegiado,
                    fecha_Nacimiento: fechaNac,
                    Perfil: profesion
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.Codigo === 200) {
                        setDatos({
                            nombre: data.NombreCompleto,
                            correo: data.Email,
                            telefono: data.TelefonoPersonal,
                            colegiado: data.Colegiado,
                            profesion: profesion,
                            acompanante: false,
                            recoger: "",
                            nombreSede: ""
                        });
                        setConsultado(true);
                        setIsLoading(false);
                    } else {
                        setErrorBuscar(true);
                        setIsLoading(false);
                        setMsgError("Por favor, verifique los datos ingresados e intente de nuevo.");
                    }
                })
                .catch((error) => {
                    //console.error('Error:', error);
                    setErrorBuscar(true);
                    setIsLoading(false);
                    setMsgError("Por favor, intente de nuevo más tarde.");
                });

        } catch (error) {
            //console.log(error);
            setErrorBuscar(true);
            setIsLoading(false);
            setMsgError("Por favor, recargue la página e intente de nuevo.");
        }
    };

    const Recomendaciones = () => {
        //props.cambiar();
        cambiar();
    };

    const regresarInicio = () => {
        setConsultado(false);
        setFechaNac("");
        setColegiado("");
        setProfesion("");
        setErrorBuscar(false);
        setDatos({
            nombre: "",
            correo: "",
            telefono: "",
            colegiado: "",
            profesion: ""
        });
    }

    const registrarSinColegiado = () => {
        setConsultado(true);
    }

    return( 

        <Grid container spacing={4}>

            <Grid item xs={12}>
                <img className={classes.img} alt="complex" src={bienvenida3} style={{ marginTop: "5vh", maxWidth: "40%" }} />
                <img className={classes.img} alt="complex" src={imgGala3} style={{ marginTop: "2vh", maxWidth: "40%" }} />
                <Typography align={"center"} variant={"h5"} className={classes.textotitulo} color={"primary"} style={{ color: "#585756", marginTop: "3vh" }}>
                    Centro Cultural Miguel Ángel Asturias
                </Typography>
                <Typography align={"center"} variant={"h5"} className={classes.textotitulo} color={"primary"} style={{ color: "#585756"}}>
                    05 de septiembre
                </Typography>
                <Typography align={"center"} variant={"h5"} className={classes.textotitulo} color={"primary"} style={{ color: "#585756"}}>
                    19:00 a 21:00 horas
                </Typography>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item sm={4}>
                    </Grid>
                    <Grid item sm={5}>
                        <img className={classes.img} alt="complex" src={invitado} style={{ maxWidth: "80%" }} />
                    </Grid>
                    <Grid item sm={3}>
                        <img className={classes.img} alt="complex" src={logo} style={{ maxWidth: "40%" }} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>

                <Widget
                    disableWidgetMenu                       
                    upperTitle
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                >
                    {!consultado && datos.nombre === "" ? (
                        <Grid container spacing={4}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                            <Grid item xs={12}>
                                <Typography align={"center"} variant={"h2"} className={classes.textotitulo} color={"primary"}>
                                    Registro
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField fullWidth value={colegiado} onChange={handleChangeColegiado}  id="colegiado" label="No. de Colegiado*" variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField   type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }} 
                                fullWidth  
                                value={fechaNac} 
                                onChange={e => setFechaNac(e.target.value)}  
                                id="fecha-nac" 
                                label="Fecha de Nacimiento*" 
                                variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel id="profesion" className={classes.profesionLabel}>
                                        Profesión
                                    </FormLabel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                        <RadioGroup aria-label="profesion" name="profesion-group" value={profesion} onChange={SeleccionarProfesion}>
                                            <FormControlLabel value="1" control={<Radio />} label="Arquitecto" />
                                            <FormControlLabel value="2" control={<Radio />} label="Diseñador Industrial" />
                                        </RadioGroup>
                                        </Grid>
                                        <Grid item xs={6}>
                                        <RadioGroup aria-label="profesion" name="profesion-group" value={profesion} onChange={SeleccionarProfesion}>
                                            <FormControlLabel value="3" control={<Radio />} label="Diseñador Gráfico" />
                                            <FormControlLabel value="4" control={<Radio />} label="Diseñador 3D" />
                                        </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>


                            {  errorBuscar &&
                                <Grid item xs={12}>
                                    <Alert severity="error" onClose={() => setErrorBuscar(false)}>
                                        {msgError}
                                    </Alert>
                                </Grid>
                            }

                            <Grid item xs={12}>
                                {isLoading ? (
                                    <div  className={classes.textotitulo}>
                                        <CircularProgress/>
                                    </div>                                   
                                ) : (
                                    <Button
                                        disabled={colegiado.length === 0 || profesion.length === 0 || fechaNac.length === 0}
                                        variant="contained" color="secondary" onClick={ConsultarDatos} className={classes.estiloboton}
                                    >
                                        Siguiente
                                    </Button>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    color="secondary"
                                    align="center"
                                    onClick={registrarSinColegiado} // Actualiza el estado de registrarse a true al hacer clic
                                    style={{ cursor: "pointer" }}
                                >
                                    <strong>Regístrate sin colegiado</strong>
                                </Typography>
                            </Grid>

                        </Grid>
                    ) : (
                        <>
                        <IconButton onClick={regresarInicio}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Grid container spacing={4}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                            {datos.colegiado === "" && (
                                <>
                                <Grid item xs={12}>
                                    <Typography align={"center"} variant={"h2"} className={classes.textotitulo} color={"primary"}>
                                        Registro
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>            
                                    <Divider />
                                </Grid>

                                <Grid item xs={12}>

                                    <Grid container spacing={4} alignItems={"center"} justify={"space-between"} direction={'row'}>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={datos.nombre}
                                                onChange={handleChangeNombre}
                                                id="nombreCompleto"
                                                label="Nombre Completo*"
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField 
                                                fullWidth 
                                                type="email" 
                                                value={datos.correo} 
                                                onChange={handleChangeCorreo}
                                                id="correo" 
                                                label="Correo Electrónico*" 
                                                variant="outlined" 
                                                error={ datos.correo===""? false :/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(datos.correo) ? false : true}      
                                                helperText={ datos.correo===""? "" :/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(datos.correo) ? "" : "Formato invalido"}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={datos.telefono}
                                                onChange={handleChangeTelefono}
                                                id="telefono"
                                                label="Número de Celular*"
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant={"subtitle2"} align={"justify"} >
                                                Si desea asistir con acompañantes, por favor, indíquelo marcando esta opción. Tras confirmar su registro, nos comunicaremos con usted para solicitar los datos de sus acompañantes.
                                            </Typography>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={datos.acompanante}
                                                            onChange={() => setDatos({...datos, acompanante: !datos.acompanante})}
                                                            name="checkedA"
                                                            color="primary"
                                                        />}
                                                    label="Acompañante"
                                                />
                                            </FormGroup>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="recoger">Recoger invitacion en</InputLabel>
                                                <Select
                                                    labelId="recoger"
                                                    id="recoger-select"
                                                    value={datos.recoger}
                                                    onChange={HandleRecoger}
                                                    label="Recoger invitacion en"
                                                >
                                                    <MenuItem value={"actividad"}>Recoger el día de la actividad</MenuItem>
                                                    <MenuItem value={"sede"}>Recoger en Sede del Colegio de Arquitectos o Subsede</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {datos.recoger === "sede" && (
                                            <>
                                            <Grid item xs={12}>
                                                <Typography align={"center"} variant={"h3"} className={classes.textotitulo} color={"primary"}>
                                                    Sedes
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
                                                <FormControl>
                                                    <Grid container spacing={2} alignContent={"center"} justifyContent={"flex-end"}>
                                                        <Grid item xs={6}>
                                                        <RadioGroup aria-label="profesion" name="profesion-group" value={datos.nombreSede} onChange={SeleccionarSede}>
                                                            <FormControlLabel value="Sede Central" control={<Radio />} label="Sede Central" />
                                                            <FormControlLabel value="Antigua Guatemala" control={<Radio />} label="Antigua Guatemala" />
                                                            <FormControlLabel value="Cobán" control={<Radio />} label="Cobán" />
                                                            <FormControlLabel value="Huehuetenango" control={<Radio />} label="Huehuetenango" />
                                                            <FormControlLabel value="Jutiapa - Jalapa" control={<Radio />} label="Jutiapa - Jalapa" />
                                                            <FormControlLabel value="Petén" control={<Radio />} label="Petén" />
                                                        </RadioGroup>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                        <RadioGroup aria-label="profesion" name="profesion-group" value={datos.nombreSede} onChange={SeleccionarSede}>
                                                            <FormControlLabel value="Quetzaltenango" control={<Radio />} label="Quetzaltenango" />
                                                            <FormControlLabel value="Quiché" control={<Radio />} label="Quiché" />
                                                            <FormControlLabel value="San Marcos" control={<Radio />} label="San Marcos" />
                                                            <FormControlLabel value="Suchitepequez - Retalhuleu" control={<Radio />} label="Suchitepequez - Retalhuleu" />
                                                            <FormControlLabel value="Zacapa" control={<Radio />} label="Zacapa" />
                                                            <FormControlLabel value="Chimaltenango" control={<Radio />} label="Chimaltenango" />
                                                        </RadioGroup>
                                                        </Grid>
                                                    </Grid>
                                                </FormControl>
                                            </Grid>
                                            </>
                                        )}

                                        {/*<ReCaptcha
                                            action='main'
                                            sitekey='6Lc2hYonAAAAAMl2TtSPOatlmigxXbjrjKJ811vI'
                                            verifyCallback={verifyCallback}
                                        />*/}

                                        { error===false &&
                                            <Grid item xs={12}>
                                                {isLoading ? (
                                                    <div  className={classes.textotitulo}>
                                                        <CircularProgress/>
                                                    </div>                                   
                                                ) : (
                                                    <Button 
                                                        disabled={
                                                            datos.correo.length === 0 || datos.nombre.length === 0 || datos.telefono.length === 0 || datos.recoger.length === 0 ||
                                                        ( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(datos.correo) ? false : true ) ||
                                                        ( datos.recoger === "sede" && datos.nombreSede.length === 0)
                                                        }
                                                        variant="contained" color="secondary" onClick={Recomendaciones} className={classes.estiloboton}>
                                                        Siguiente
                                                    </Button>
                                                )}
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                </>
                            )}

                            {datos.colegiado !== "" && (
                                <>
                                
                                <Grid item xs={12}>
                                    <Typography align={"center"} variant={"h1"} className={classes.textotitulo} color={"primary"}>
                                        Datos almacenados en el sistema
                                    </Typography>
                                </Grid>

                                {/*<Grid item xs={12}>            
                                    <Typography align={"center"} className={classes.textoDescripcion}>
                                        Por favor, confirme los datos para completar su registro.
                                    </Typography>   
                                </Grid>*/}

                                <Grid item xs={12}>            
                                    <Divider />
                                </Grid>

                                <Grid item xs={12}>

                                    <Grid container spacing={4} alignItems={"center"} justify={"space-between"} direction={'row'}>

                                        <Grid item xs={12}>
                                            <TextField 
                                                fullWidth 
                                                type="email" 
                                                value={datos.correo} 
                                                id="correo" 
                                                label="Correo Electrónico*" 
                                                variant="outlined" 
                                                error={ datos.correo===""? false :/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(datos.correo) ? false : true}      
                                                helperText={ datos.correo===""? "" :/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(datos.correo) ? "" : "Formato invalido"}
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={datos.colegiado}
                                                id="colegiado"
                                                label="No. de Colegiado*"
                                                variant="outlined"
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={datos.nombre}
                                                id="nombreCompleto"
                                                label="Nombre Completo*"
                                                variant="outlined"
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <FormLabel id="profesion" className={classes.profesionLabel}>
                                                    Profesión
                                                </FormLabel>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                    <RadioGroup aria-label="profesion" name="profesion-group" value={datos.profesion} onChange={SeleccionarProfesion}>
                                                        <FormControlLabel value="1" control={<Radio disabled />} label="Arquitecto" />
                                                        <FormControlLabel value="2" control={<Radio disabled />} label="Diseñador Industrial" />
                                                    </RadioGroup>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    <RadioGroup aria-label="profesion" name="profesion-group" value={datos.profesion} onChange={SeleccionarProfesion}>
                                                        <FormControlLabel value="3" control={<Radio disabled />} label="Diseñador Gráfico" />
                                                        <FormControlLabel value="4" control={<Radio disabled />} label="Diseñador 3D" />
                                                    </RadioGroup>
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        </Grid>

                                        {/*<ReCaptcha
                                            action='main'
                                            sitekey='6Lc2hYonAAAAAMl2TtSPOatlmigxXbjrjKJ811vI'
                                            verifyCallback={verifyCallback}
                                            />*/}

                                        <Grid item xs={12}>
                                            <Typography variant={"subtitle2"} align={"justify"} >
                                                Si desea asistir con acompañantes, por favor, indíquelo marcando esta opción. Tras confirmar su registro, nos comunicaremos con usted para solicitar los datos de sus acompañantes.
                                            </Typography>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={datos.acompanante}
                                                            onChange={() => setDatos({...datos, acompanante: !datos.acompanante})}
                                                            name="acompanante"
                                                            color="primary"
                                                        />}
                                                    label="Acompañante"
                                                />
                                            </FormGroup>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="recoger">Recoger invitacion en</InputLabel>
                                                <Select
                                                    labelId="recoger"
                                                    id="recoger-select"
                                                    value={datos.recoger}
                                                    onChange={HandleRecoger}
                                                    label="Recoger invitacion en"
                                                >
                                                    <MenuItem value={"actividad"}>Recoger el día de la actividad</MenuItem>
                                                    <MenuItem value={"sede"}>Recoger en Sede del Colegio de Arquitectos o Subsede</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        {datos.recoger === "sede" && (
                                            <>
                                            <Grid item xs={12}>
                                                <Typography align={"center"} variant={"h3"} className={classes.textotitulo} color={"primary"}>
                                                    Sedes
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
                                                <FormControl>
                                                    <Grid container spacing={2} alignContent={"center"} justifyContent={"flex-end"}>
                                                        <Grid item xs={6}>
                                                        <RadioGroup aria-label="profesion" name="profesion-group" value={datos.nombreSede} onChange={SeleccionarSede}>
                                                            <FormControlLabel value="Sede Central" control={<Radio />} label="Sede Central" />
                                                            <FormControlLabel value="Antigua Guatemala" control={<Radio />} label="Antigua Guatemala" />
                                                            <FormControlLabel value="Cobán" control={<Radio />} label="Cobán" />
                                                            <FormControlLabel value="Huehuetenango" control={<Radio />} label="Huehuetenango" />
                                                            <FormControlLabel value="Jutiapa - Jalapa" control={<Radio />} label="Jutiapa - Jalapa" />
                                                            <FormControlLabel value="Petén" control={<Radio />} label="Petén" />
                                                        </RadioGroup>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                        <RadioGroup aria-label="profesion" name="profesion-group" value={datos.nombreSede} onChange={SeleccionarSede}>
                                                            <FormControlLabel value="Quetzaltenango" control={<Radio />} label="Quetzaltenango" />
                                                            <FormControlLabel value="Quiché" control={<Radio />} label="Quiché" />
                                                            <FormControlLabel value="San Marcos" control={<Radio />} label="San Marcos" />
                                                            <FormControlLabel value="Suchitepequez - Retalhuleu" control={<Radio />} label="Suchitepequez - Retalhuleu" />
                                                            <FormControlLabel value="Zacapa" control={<Radio />} label="Zacapa" />
                                                            <FormControlLabel value="Chimaltenango" control={<Radio />} label="Chimaltenango" />
                                                        </RadioGroup>
                                                        </Grid>
                                                    </Grid>
                                                </FormControl>
                                            </Grid>
                                            </>
                                        )}

                                    { error===false &&
                                        <Grid item xs={12}>
                                        {isLoading ? (
                                            <div  className={classes.textotitulo}>
                                                <CircularProgress/>
                                            </div>                                   
                                            ) : (
                                            <Button 
                                                disabled={
                                                    datos.correo.length === 0 || datos.colegiado.length === 0 || datos.nombre.length === 0 ||  datos.telefono.length === 0 || datos.recoger.length === 0 ||
                                                ( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(datos.correo) ? false : true ) ||
                                                ( datos.recoger === "sede" && datos.nombreSede.length === 0)
                                                }
                                                variant="contained" color="secondary" onClick={Recomendaciones} className={classes.estiloboton}>
                                                Siguiente
                                            </Button>
                                            )}
                                        </Grid>
                                        }

                                {  error &&
                                    <Grid item xs={12}>
                                            <Alert severity="error">Ocurrió un error al procesar la solicitud, intenta recargar la pagina</Alert>
                                        </Grid>}

                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>            
                                    <Divider />
                                </Grid>
                                </>
                            )}

                        </Grid>
                        </>
                    )}

                </Widget>

            </Grid>




        </Grid>

       
    );


}

export default Formulario;